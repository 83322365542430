import * as Sentry from '@sentry/nuxt'
import { useRuntimeConfig } from '#imports'

Sentry.init({
  dsn: useRuntimeConfig().public.sentry.dns,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})
